var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{attrs:{"app":""}},[_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-img',{staticClass:"ma-8",attrs:{"src":_vm.logoImage,"alt":"Atletic.Me Logo"}}),_c('v-divider'),_c('v-list',{staticClass:"mt-8",attrs:{"nav":""}},[_c('v-list-item-group',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('v-container',{staticClass:"horizontal-container rounded-lg relative",staticStyle:{"margin-top":"105px !important","background-size":"100%","background":"transparent !important"},style:(_vm.bgStyle)},[(_vm.isAuthenticated && !_vm.hiddenAppBar)?_c('v-app-bar',{class:{
        'mb-4': !_vm.$vuetify.breakpoint.smAndDown,
        'rounded-b-0': _vm.$vuetify.breakpoint.smAndDown,
      },style:({
        bottom: _vm.$vuetify.breakpoint.smAndDown ? '-1px' : undefined,
        top: _vm.$vuetify.breakpoint.smAndDown ? 'auto' : undefined,
      }),attrs:{"rounded":"","elevation":"0","dark":_vm.$vuetify.breakpoint.smAndDown || undefined,"height":!_vm.$vuetify.breakpoint.smAndDown ? 'auto' : '65px',"fixed":_vm.$vuetify.breakpoint.smAndDown}},[_c('div',{staticClass:"d-flex align-center w-full"},[_c('div',{staticClass:"d-flex align-center w-full my-2",class:{
            'justify-end': !_vm.$vuetify.breakpoint.smAndDown,
            'justify-space-around': _vm.$vuetify.breakpoint.smAndDown,
            'my-2': _vm.$vuetify.breakpoint.smAndDown,
          },staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.items),function(item,index){return _c('v-btn',{key:index,attrs:{"to":item.path,"text":"","large":_vm.$vuetify.breakpoint.smAndDown,"rounded":_vm.$vuetify.breakpoint.smAndDown}},[_c('div',{staticClass:"d-flex align-center",class:{
                'flex-column': _vm.$vuetify.breakpoint.smAndDown,
                'gap-1': _vm.$vuetify.breakpoint.smAndDown,
              }},[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('small',[_vm._v(_vm._s(item.text))])],1)])}),1)])]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"flex-grow-1",class:{ 'mb-8': _vm.$vuetify.breakpoint.smAndDown }}),_c('app-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }